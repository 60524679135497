var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"all"},[_c('div',{staticClass:"table"},[_c('el-form',{ref:"search",attrs:{"inline":true,"model":_vm.search,"size":"small"}},[_c('el-form-item',{attrs:{"label":"用户名称"}},[_c('el-input',{staticClass:"EquipmentMarginLeft",attrs:{"size":"mini","clearable":"","placeholder":"请输入关键字"},model:{value:(_vm.search.userName),callback:function ($$v) {_vm.$set(_vm.search, "userName", $$v)},expression:"search.userName"}})],1),_c('el-form-item',{attrs:{"label":"旧表表编号"}},[_c('el-input',{staticClass:"EquipmentMarginLeft",attrs:{"size":"mini","clearable":"","placeholder":"请输入关键字"},model:{value:(_vm.search.oldSheetSteel),callback:function ($$v) {_vm.$set(_vm.search, "oldSheetSteel", $$v)},expression:"search.oldSheetSteel"}})],1),_c('el-form-item',{attrs:{"label":"新表表编号"}},[_c('el-input',{staticClass:"EquipmentMarginLeft",attrs:{"size":"mini","clearable":"","placeholder":"请输入关键字"},model:{value:(_vm.search.sheetSteel),callback:function ($$v) {_vm.$set(_vm.search, "sheetSteel", $$v)},expression:"search.sheetSteel"}})],1),_c('el-form-item',[_c('div',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"warning","size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")])],1)])],1),_c('el-table',{ref:"tableList",staticStyle:{"width":"100%","margin":"15px 0"},attrs:{"data":_vm.tableData,"border":"","height":"calc(100vh - 335px)","stripe":true,"header-cell-style":{
                    color: '#333',
                    fontFamily: 'MicrosoftYaHeiUI',
                    fontSize: '14px',
                    fontWeight: 900,
                    background: '#f8f8f9',
                    textAlign:'left',
                    }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"用户名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"oldSheetSteel","label":"旧表表编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"oldTableTypeName","label":"旧表燃气表类型","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"oldTableDirectionName","label":"旧表表向","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"sheetSteel","label":"新表表编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"tableTypeName","label":"新表燃气表类型","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"tableDirectionName","label":"新表表向","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"oldMechanicalData","label":"旧表机械数","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"newMechanicalData","label":"新表机械初值","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"residualVolume","label":"剩余气量","show-overflow-tooltip":""}}),(_vm.$route.query.type=='user')?_c('el-table-column',{attrs:{"prop":"tableDirectionName","label":"左右表","show-overflow-tooltip":""}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"updateTableBy","label":"换表人","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"updateTableTime","label":"换表时间","show-overflow-tooltip":""}})],1),_c('div',{staticClass:"pageBox"},[_c('el-pagination',{attrs:{"current-page":_vm.search.current,"background":true,"page-sizes":[30, 50, 100],"page-size":_vm.search.size,"layout":"total, prev, pager, next, sizes, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }