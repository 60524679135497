<template>
    <div>
        <div class="content">
            <div class="all">
                <div class="table">
                    <!--搜索框-->
                    <el-form ref="search" :inline="true" :model="search" size="small">
                        
                        <el-form-item label="用户名称">
                            <el-input
                                v-model="search.userName"
                                size="mini"
                                clearable
                                placeholder="请输入关键字"
                                class="EquipmentMarginLeft"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="旧表表编号">
                            <el-input
                                v-model="search.oldSheetSteel"
                                size="mini"
                                clearable
                                placeholder="请输入关键字"
                                class="EquipmentMarginLeft"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="新表表编号">
                            <el-input
                                v-model="search.sheetSteel"
                                size="mini"
                                clearable
                                placeholder="请输入关键字"
                                class="EquipmentMarginLeft"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                        <div>
                            <el-button type="primary" size="small" @click="getList(1)"
                            >查询</el-button
                            >
                            <el-button type="warning" size="small" @click="reset"
                            >重置</el-button
                            >
                        </div>
                        </el-form-item>
                    </el-form>
                    <!--数据表格-->
                    <el-table
                        ref="tableList"
                        :data="tableData"
                        style="width: 100%; margin: 15px 0"
                        border
                        height="calc(100vh - 335px)"
                        :stripe="true"
                        :header-cell-style="{
                        color: '#333',
                        fontFamily: 'MicrosoftYaHeiUI',
                        fontSize: '14px',
                        fontWeight: 900,
                        background: '#f8f8f9',
                        textAlign:'left',
                        }"
                    >
                        <el-table-column type="index" label="序号" width="60"> </el-table-column>
                        <el-table-column prop="userName" label="用户名称" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="oldSheetSteel" label="旧表表编号" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="oldTableTypeName" label="旧表燃气表类型" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="oldTableDirectionName" label="旧表表向" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="sheetSteel" label="新表表编号" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="tableTypeName" label="新表燃气表类型" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="tableDirectionName" label="新表表向" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="oldMechanicalData" label="旧表机械数" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="newMechanicalData" label="新表机械初值"  show-overflow-tooltip></el-table-column>
                        <el-table-column prop="residualVolume" label="剩余气量"  show-overflow-tooltip></el-table-column>
                        <el-table-column prop="tableDirectionName" label="左右表"  show-overflow-tooltip v-if="$route.query.type=='user'"></el-table-column>
                        <el-table-column prop="updateTableBy" label="换表人"  show-overflow-tooltip></el-table-column>
                        <el-table-column prop="updateTableTime" label="换表时间" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <div class="pageBox">
                        <el-pagination
                            :current-page="search.current"
                            :background="true"
                            :page-sizes="[30, 50, 100]"
                            :page-size="search.size"
                            layout="total, prev, pager, next, sizes, jumper"
                            :total="total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
import {
  entiretyWithReplace
} from "@/RequestPort/induction";
export default {
    name: 'changeTbRecord',
    data () {
        return {
            search: {
                userName: '',	// 用户名称
                oldSheetSteel: '',	// 旧表表编号
                sheetSteel: '',	// 新表表编号
                current: 1,	// 当前页,默认值为1
                size: 100,	// 当前页条数,默认值为10
            },
            total: 0,
            tableData: []
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList(type) {
            if(type==1) {
                this.search.current = 1
                this.tableData = []
            }
            entiretyWithReplace(this.search).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        reset() {
            this.search = {
                userName: '',	// 用户名称
                oldSheetSteel: '',	// 旧表表编号
                sheetSteel: '',	// 新表表编号
                current: 1,	// 当前页,默认值为1
                size: 100,	// 当前页条数,默认值为10
            }
            this.getList()
        },
        handleCurrentChange(current) {
            this.search.current = current;
            this.getList();
        },
        handleSizeChange(val) {
            this.search.size = val;
            this.currentChange(1);
        },
    }
}
</script>
 
<style scoped lang="less">
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  height: calc(100vh - 150px);
}
</style>